export default class Mobile {
    static isMobile() {
        return window.innerWidth < 768 || /Mobi|Android/i.test(navigator.userAgent);
    }
    static onResize(callback) {
        window.addEventListener('resize', callback);
    }
    static removeResize(callback) {
        window.removeEventListener('resize', callback);
    }
}
